import { admin2 } from '@/api/instance'

// => 取得遊戲方式
export const GetGameMode = async ({ shopId, start, limit }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameMode`,
    params: { start, limit },
  })
  return res
}

// => 取得遊戲方式總數
export const GetGameModeCount = async ({ shopId, start, limit }) => {
  const res = await admin2({
    method: 'get',
    url: `/${shopId}/memberGameMode/count`,
    params: { start, limit },
  })
  return res
}
